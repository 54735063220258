
import { Vue, Component } from "vue-property-decorator";
import {commitSwitchLanguage} from "@/store/admin/mutations";
import {readLanguage} from "@/store/admin/getters";
import pdf from "vue-pdf";
import {api} from "@/api";
import {readToken} from "@/store/main/getters";
@Component({
  components: {pdf},
})
export default class VisitAll extends Vue {
    public pdfsrc = "";
    public pageCount = 28;

  get language() {
    return readLanguage(this.$store);
  }
  
  public swtichLanguageEng() {
    console.log("Englisch");
    //True means eng
    if(this.language) {
      commitSwitchLanguage(this.$store);
    }
  }

  public swtichLanguageDe() {
    //False means de
    console.log("DEUTSCH");
    if(!this.language) {
      commitSwitchLanguage(this.$store);
    }
  }

  public download() {
    var win = window.open(this.pdfsrc);
    //setTimeout(function(){ win.document.title = 'Maggie.pdf'; }, 1000);
  }

  public async mounted() {
    let data = await api.getVisitPDFAll(readToken(this.$store), parseInt(this.$router.currentRoute.params.id), this.getLan)
      .then(function (response) {
        // console.log(response)
        return response.data;
      })
      .catch( function (error) {
        console.log("ERROR: " + error);
      });
      // console.log(data)
    // let blob = new Blob([data], {type: 'application/pdf'});
    // console.log(blob)
    // let link = window.URL.createObjectURL(data);
    // window.open(link)

    // let encodedString = btoa(String.fromCharCode.apply(null, new Uint8Array(data)));
    // console.log(encodedString)
    // //Now converting it into something useful
    var arrrayBuffer = base64ToArrayBuffer(data); //data is the base64 encoded string
    function base64ToArrayBuffer(base64) {
      var binaryString = window.atob(base64);
      var binaryLen = binaryString.length;
      var bytes = new Uint8Array(binaryLen);
      for (var i = 0; i < binaryLen; i++) {
          var ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
      }
      return bytes;
    }
    // console.log(arrrayBuffer)
    var blob = new Blob([arrrayBuffer], {type: "application/pdf"});
    // console.log(blob);
    // console.log(typeof data)
    var link = window.URL.createObjectURL(blob);
    console.log(link)
    // window.open(link)
    this.pdfsrc = link ;
  }
  get getLan() {
    if(this.language) {
      return "de"
    }
    return "eng"
  }


}
